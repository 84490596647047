import Link from 'next/link';
import React from 'react';
import { Issue } from '../../lib/sunletter';
import { AlignedExternalIcon } from '../../pages';
import { Button, Flex, Paper, Typography } from '../../ui';

export const SocialBox: React.FC<{ issues?: Issue[] }> = ({ issues }) => (
  <Flex flexDirection="column" alignItems="start" gap={8}>
    {/* <Typography.Link href="/sunletter" textDecoration="none">
      <Button variant="hollow" leadingIcon={<LetterEnvelopeThinIcon />}>
        Get My Bi-Weekly Letter
      </Button>
    </Typography.Link> */}

    <Typography.Caption size="L" opacity={0.5}>
      I also write newsletters.{' '}
      <Link href="https://bartaxyz.substack.com/" legacyBehavior>
        <Typography.Link href="https://bartaxyz.substack.com/">Subscribe here!</Typography.Link>
      </Link>
    </Typography.Caption>

    {issues && (
      <Typography.Link href={issues[0].url} textDecoration="none">
        <Paper padding={16} variant="solid">
          <Flex gap={4} flexDirection="column">
            <Typography.Caption size="L" opacity={0.5}>
              Latest Issue:
            </Typography.Caption>
            <Typography.Caption size="XL">
              {issues[0].title} <AlignedExternalIcon />
            </Typography.Caption>

            {/* <Typography.Link href="/sunletter" textDecoration="none">
          <Button variant="hollow" leadingIcon={<LetterEnvelopeThinIcon />}>
          Get My Bi-Weekly Letter
          </Button>
        </Typography.Link> */}
          </Flex>
        </Paper>
      </Typography.Link>
    )}

    {/* <Flex gap={24}>
      <Typography.Link
        href="https://twitter.com/bartaxyz"
        textDecoration="none"
      >
        <Button size="S" variant="hollow" leadingIcon={<TwitterThinIcon />}>
          Twitter
        </Button>
      </Typography.Link>
      <Typography.Link href="https://github.com/bartaxyz" textDecoration="none">
        <Button size="S" variant="hollow" leadingIcon={<GithubThinIcon />}>
          GitHub
        </Button>
      </Typography.Link>
    </Flex> */}
  </Flex>
);
