import React from 'react';
import { Box, BoxProps, Reveal } from '../../ui';

const NoReveal: React.FC<React.PropsWithChildren<BoxProps>> = ({ children, ...props }) => (
  <Box
    marginLeft={{ desktop: 40, tablet: 24, phone: 16 }}
    marginRight={{ desktop: 40, tablet: 24, phone: 16 }}
  >
    <Box
      maxWidth={800}
      margin="auto"
      marginTop={160}
      marginBottom={160}
      {...props}
    >
      {children}
    </Box>
  </Box>
);

export const ContentBox: React.FC<React.PropsWithChildren<BoxProps>> & {
  NoReveal: typeof NoReveal;
  children?: React.ReactNode;
} = ({ children, ...props }) => (
  <NoReveal {...props}>
    <Reveal inactiveOpacity={0.6}>{children}</Reveal>
  </NoReveal>
);

ContentBox.NoReveal = NoReveal;
