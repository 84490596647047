import React, { useMemo } from 'react';
import { ExternalIcon } from 'xyz-icon-set-react';

import SEO from '../components/seo';
import { SocialBox } from '../components/SocialBox/SocialBox';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Box, Divider, Flex, Grid, Reveal, styled, Typography } from '../ui';

import { ContentBox } from '../components/ContentBox/ContentBox';
import { Footer } from '../components/Footer/Footer';

import Link from 'next/link';
import { PlainLayout } from '../layouts/PlainLayout';
import { GalleryScene } from '../scenes/GalleryScene/GalleryScene';
import letters from '../assets/letters.json';
import { useSystemTheme } from '../layouts/utils/useSystemTheme';
import { rgba } from 'polished';

const IndexNarrowContentBox: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <Box
    marginLeft={{ desktop: 40, tablet: 24, phone: 16 }}
    marginRight={{ desktop: 40, tablet: 24, phone: 16 }}
  >
    <Box maxWidth={400} margin="auto" marginTop={160} marginBottom={160}>
      <Reveal inactiveOpacity={0.6}>{children}</Reveal>
    </Box>
  </Box>
);

const personalDescription =
  'Barcelona-based serial creator with engineering management & full stack engineering background';

const IndexContent: React.FC = () => {
  return (
    <React.Fragment>
      <div
        style={{
          minHeight: 'calc(100vh - 64px)',
          display: 'flex',
          alignItems: 'end',
        }}
      >
        <ContentBox.NoReveal
          maxWidth={1200}
          marginBottom={{ desktop: 80, tablet: 80, phone: 40 }}
          marginLeft={{ desktop: 80, tablet: 80, phone: 0 }}
        >
          <Box maxWidth={800}>
            <div
              style={{
                pointerEvents: 'none',
              }}
            >
              <Reveal inactiveOpacity={0.5}>
                <Typography.Caption size="L" opacity={0.5}>
                  Hey! I'm Ondřej Bárta,
                </Typography.Caption>
                <Typography.Headline level={1}>
                  {personalDescription}
                </Typography.Headline>
              </Reveal>
            </div>
            <Box marginTop={40}>
              <SocialBox />
            </Box>
          </Box>
        </ContentBox.NoReveal>
      </div>

      <ContentBox
        maxWidth={1200}
        marginTop={40}
        marginLeft={{ desktop: 80, tablet: 80, phone: 0 }}
      >
        <Box maxWidth={800}>
          <Flex flexDirection="column" gap={40}>
            <Typography.Paragraph size="L" opacity={0.5}>
              You can mostly find me at the intersection of technology and
              design. I profoundly enjoy the act of creating. A large portion of
              my free time is, therefore, dedicated to learning about and
              creating things that I find useful, beautiful or both.
            </Typography.Paragraph>

            <Flex flexDirection="column" gap={16}>
              <Typography.Caption size="L" opacity={0.5}>
                CURRENTLY
              </Typography.Caption>

              <Typography.Caption size="XL">
                Engineering Manager at{' '}
                <Typography.Link href="https://glassnode.com/">
                  Glassnode
                </Typography.Link>
                .
              </Typography.Caption>

              <Typography.Caption size="XL">
                Technical consultant in{' '}
                <Typography.Link href="https://kilta.com/">
                  Kilta
                </Typography.Link>{' '}
                (former{' '}
                <Typography.Link href="https://statusquack.com/">
                  Status Quack
                </Typography.Link>
                )
              </Typography.Caption>

              <Typography.Caption size="XL">
                Member of{' '}
                <Typography.Link href="https://techsquat.com">
                  Techsquat
                </Typography.Link>
              </Typography.Caption>
            </Flex>

            <Flex flexDirection="column" gap={16}>
              <Typography.Caption size="L" opacity={0.5}>
                PAST
              </Typography.Caption>

              <Typography.Caption size="XL">
                Engineering Manager at{' '}
                <Typography.Link href="https://www.nuri.com">
                  Nuri (formerly Bitwala)
                </Typography.Link>
                .
              </Typography.Caption>

              <Typography.Caption size="XL">
                Responsible for frontend development &amp; responsive print
                design at{' '}
                <Typography.Link href="https://www.linkedin.com/company/jackova-tov%C3%A1rna/about/">
                  Jackova Továrna
                </Typography.Link>
              </Typography.Caption>
            </Flex>
          </Flex>
        </Box>
      </ContentBox>

      <Divider.Horizontal />

      <Grid
        templateColumns={{
          desktop: '1fr auto 1fr',
          tablet: '1fr auto 1fr',
          phone: 'auto',
        }}
      >
        <IndexNarrowContentBox>
          <Box marginTop={24} marginBottom={40}>
            <Typography.Caption size="L" opacity={0.5}>
              PET PROJECTS
            </Typography.Caption>
          </Box>

          <Flex flexDirection="column" gap={16}>
            {[
              {
                title: 'Covidbility',
                href: 'https://bartaxyz.github.io/covidbility/',
                external: true,
                description: 'COVID-19 Data Experiment',
              },
              {
                title: 'Daily Tasks',
                href: 'https://twitter.com/bartaxyz/status/1508072375816003587',
                external: true,
                description: 'Opinionated MacOS-first task app',
              },
              {
                title: 'XYZ Icon Set',
                href: '/pet-projects/xyz-icon-set',
                description: 'Icon set with over 200 icons',
              },

              {
                title: 'Lofi Chill Music Production',
                href: 'https://www.youtube.com/watch?v=2B01AwrdBPU',
                external: true,
              },

              {
                title: 'React Tree List',
                href: 'https://github.com/bartaxyz/react-tree-list',
                external: true,
              },

              {
                title: 'Lovechain.land',
                href: 'https://blockchain-love.web.app',
                external: true,
              },

              {
                title: 'TSA Wallpaper For Google Pixel 2XL',
                href: '/pet-projects/tsa-wallpaper-for-google-pixel-2xl',
              },

              {
                title: 'Bitcoin Wear OS Watch Face',
                href: 'https://github.com/bartaxyz/crypto-watch-face/tree/main',
              },
            ].map(({ title, description, href, external }) => (
              <Typography.Caption size="XL" key={title}>
                <Typography.Link href={href}>
                  {title}
                  <AlignedExternalIcon />
                </Typography.Link>
                {description && <> - {description}</>}
              </Typography.Caption>
            ))}

            <Typography.Caption size="L" opacity={0.5}>
              and many others…
            </Typography.Caption>
          </Flex>
        </IndexNarrowContentBox>

        <Box>
          <Divider.Vertical />
          <Divider.Horizontal />
        </Box>

        <IndexNarrowContentBox>
          <Box marginTop={24} marginBottom={40}>
            <Typography.Caption size="L" opacity={0.5}>
              OTHER ACTIVITIES
            </Typography.Caption>
          </Box>

          <Typography.Caption size="XL">
            <Link href="/books" legacyBehavior>
              <Typography.Link href="/books/">
                Reading Challenge 2022
              </Typography.Link>
            </Link>
            ,{' '}
            <Link href="/books/2021" legacyBehavior>
              <Typography.Link href="/books/2021">2021</Typography.Link>
            </Link>
            ,{' '}
            <Link href="/books/2020" legacyBehavior>
              <Typography.Link href="/books/2020">2020</Typography.Link>
            </Link>
            ,{' '}
            <Link href="/books/2019" legacyBehavior>
              <Typography.Link href="/books/2019">2019</Typography.Link>
            </Link>
          </Typography.Caption>

          <Box marginTop={80} marginBottom={40}>
            <Typography.Caption size="L" opacity={0.5}>
              PRESS
            </Typography.Caption>
          </Box>

          <Flex flexDirection="column" gap={16}>
            <Typography.Caption size="XL">
              Article:{' '}
              <Typography.Link href="https://tyinternety.cz/technologie/laska-blockchain-lovechain-nejtrvalejsi-zpusob-vyznavani-lasky/">
                Love + Blockchain = Lovechain, the most permanent profession of
                love
              </Typography.Link>{' '}
              🇨🇿
              <AlignedExternalIcon />
            </Typography.Caption>

            <Typography.Caption size="XL">
              Featured on{' '}
              <Typography.Link href="https://kilta.com/#trends">
                Kilta newsletter
              </Typography.Link>{' '}
              🇨🇿
              <AlignedExternalIcon />
            </Typography.Caption>
          </Flex>
        </IndexNarrowContentBox>
      </Grid>

      <Footer />
    </React.Fragment>
  );
};

export const AlignedExternalIcon = styled(
  ({ className }: { className?: string }) => (
    <Box className={className} marginLeft={8}>
      <ExternalIcon width={12} height={12} />
    </Box>
  )
)`
  display: inline-block;
  font-size: 0;
  vertical-align: top;
  opacity: 0.5;
  margin-left: 0px;
`;

const Index_: React.FC = ({ ...props }) => (
  <DefaultLayout {...props} breadcrumb={[]} background={<GalleryScene />}>
    <SEO description={personalDescription}>
      <link rel="preload" as="fetch" href="/3d/GallerySceneObject.glb" />
    </SEO>

    <IndexContent {...props} />
  </DefaultLayout>
);

const timePerLetter = 0.1;

const Index: React.FC = ({ ...props }) => {
  const [timeOnPage, setTimeOnPage] = React.useState(0);
  const { foreground, dark } = useSystemTheme();

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeOnPage((prev) => prev + 1 / 60);
    }, 1000 / 60);

    return () => clearInterval(interval);
  }, []);

  const { letter } = useMemo(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const standardisedDate = `${year}-${month}-${day}`;
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const nonBreakingSpace = '\u00A0';
    const monthName = monthNames[today.getMonth()];
    const dateFormatted = `${monthName}${nonBreakingSpace}${day}`;

    const todaysLetter = (letters as any)[standardisedDate] || 'nothing';

    const containsLineBreak = todaysLetter.includes('\n');
    const optionalIndent = containsLineBreak ? '' : '\t';

    const letterWithDate = `${dateFormatted}.\n\n${optionalIndent}${todaysLetter}`;

    return { letter: letterWithDate };
  }, []);

  const charactersDisplayed = Math.min(
    Math.floor(timeOnPage / timePerLetter),
    letter.length
  );

  return (
    <PlainLayout {...props}>
      <style>
        {`
          pre span {
            opacity: 0;
          }
          pre span.appear {
            animation: fadeIn 5s linear forwards;
          }

          @keyframes fadeIn {
            0% {
              opacity: 0;
              filter: blur(4px);
            }
            1% {
              opacity: 1;
              filter: blur(0px);
            }
            100% {
              opacity: 1;
              filter: blur(0.25px);
            }
          }
        `}
      </style>
      <div
        style={{
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'start',
          flexDirection: 'column',
          maxWidth: 480,
          margin: 'auto',
          rowGap: 16,
          userSelect: 'none',
          lineHeight: 1.5,
          wordSpacing: '0.25em',
          padding: 24,
          boxSizing: 'border-box',
        }}
        onClick={() => {
          setTimeOnPage((prev) => prev + 5 * timePerLetter);
        }}
      >
        <pre
          style={{
            fontSize: '1rem',
            fontFamily: 'Special Elite, serif',
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
          }}
        >
          {letter.split('').map((char, index) => (
            <span
              key={index}
              className={index < charactersDisplayed ? 'appear' : ''}
            >
              {char}
            </span>
          ))}
        </pre>
      </div>
    </PlainLayout>
  );
};

export default Index;
