import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { Page } from '../ui';
import { GlobalStyle } from '../ui/GlobalStyle';
import { DefaultTheme, Theme } from '../ui/themes/DefaultTheme';
import { useSystemTheme } from './utils/useSystemTheme';

const ThemeProvider = StyledThemeProvider as any;

export interface PlainLayoutProps {
  theme?: Theme;
  children?: React.ReactNode;
}

export const PlainLayout: React.FC<
  React.PropsWithChildren<PlainLayoutProps>
> = (props) => {
  const { children, theme: staticTheme } = props;

  const theme = useSystemTheme(DefaultTheme);

  return (
    <ThemeProvider theme={staticTheme || theme}>
      <Page>
        <GlobalStyle />

        {children}
      </Page>
    </ThemeProvider>
  );
};
