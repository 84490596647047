import { useEffect, useState } from 'react';

export const useMatchcMedia = (query: string) => {
  const [matches, setMatches] = useState(
    typeof matchMedia === 'undefined' ? true : matchMedia(query).matches
  );

  useEffect(() => {
    const mediaQueryList = matchMedia(query);
    const listener = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };
    mediaQueryList.addEventListener('change', listener);
    return () => {
      mediaQueryList.removeEventListener('change', listener);
    };
  }, [query]);

  return matches;
};
