import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { EffectComposer, Noise, Vignette } from '@react-three/postprocessing';
import { Physics } from '@react-three/rapier';
import gsap from 'gsap';
import { BlendFunction } from 'postprocessing';
import React, { Suspense, useEffect, useRef } from 'react';
import { Euler, MathUtils, Vector3 } from 'three';
import { styled } from '../../ui';
import { useMatchcMedia } from '../../utils/useMatchMedia';
import { GallerySceneObject } from './GallerySceneObject';

const GallerySceneContents = () => {
  const { camera, size } = useThree();
  const ambientlight = useRef<THREE.AmbientLight>(null);
  const directionalLight = useRef<THREE.DirectionalLight>(null);

  const ambientLightIntensityRanges = {
    dark: [0.05, 0],
    light: [1, 5],
  };

  const directionalLightIntensityRanges = {
    dark: [0, 0.1],
    light: [0, 1],
  };

  const isDark = useMatchcMedia('(prefers-color-scheme: dark)');

  const initialLightPosition = useRef(new Vector3(260, 120, -150));

  useEffect(() => {
    camera.position.set(5, 2, -3);

    camera.setRotationFromEuler(new Euler(0.2, 2, 0, 'XYZ'));
  }, []);

  useFrame(() => {
    const offCenterPosition = new Vector3(0, -0.9, 1.2);

    // move closer to 0,0,0 as the screen gets smaller (until 320, where it should be center)
    const scale = MathUtils.lerp(0.5, 1, size.width / 1090);

    offCenterPosition.multiplyScalar(scale);

    /**
     * POTENTIALLY SLOW; FIX
     * But it's working, so maybe nothing to fix for now
     */
    camera.lookAt(
      new Vector3().addVectors(new Vector3(0, 0, 0), offCenterPosition)
    );
  });

  const enableScrollInteraction = useRef(false);

  useEffect(() => {
    const onLoad = () => {
      /** Animate camera into position */
      const finalCameraPosition = new Vector3(
        3.669258123193299,
        0.16920743031121355,
        -1.5
      );

      const range = isDark ? 'dark' : 'light';

      /** Animate camera into position using gsap */
      const tl = gsap.timeline({
        defaults: {
          duration: 2,
          ease: 'power3.out',
        },
      });
      tl.to(camera.position, {
        x: finalCameraPosition.x,
        y: finalCameraPosition.y,
        z: finalCameraPosition.z,
      });
      /** Animate light intensity */
      const tl2 = gsap.timeline({
        defaults: {
          duration: 1,
          ease: 'power3.out',
        },
      });
      tl2.from(ambientlight.current, {
        intensity: ambientLightIntensityRanges[range][1],
      });
      tl2.to(ambientlight.current, {
        intensity: ambientLightIntensityRanges[range][0],
      });
      /** Animate directional light intensity */
      const tl3 = gsap.timeline({
        defaults: {
          duration: 1,
          ease: 'power3.out',
        },
      });
      tl3.from(directionalLight.current, {
        intensity: directionalLightIntensityRanges[range][0],
      });
      tl3.to(directionalLight.current, {
        intensity: directionalLightIntensityRanges[range][1],
      });

      setTimeout(() => {
        enableScrollInteraction.current = true;
      }, 2000);
    };

    onLoad();
  }, []);

  useEffect(() => {
    const onScroll = () => {
      if (!enableScrollInteraction.current) return;

      /** Move camera down as user scrolls using gsap tween */
      gsap.to(camera.position, {
        y: MathUtils.mapLinear(scrollY, 0, size.height / 2, 0.25, -0.25),
      });

      const range = isDark ? 'dark' : 'light';

      /** Update light intensity */
      ambientlight.current!.intensity = MathUtils.mapLinear(
        scrollY,
        0,
        size.height * 2,
        ambientLightIntensityRanges[range][0],
        ambientLightIntensityRanges[range][1]
      );

      /** UPDATE DIRECTIONAL LIGHT INTENSITY */
      directionalLight.current!.intensity = MathUtils.mapLinear(
        scrollY,
        0,
        size.height * 2,
        directionalLightIntensityRanges[range][1],
        directionalLightIntensityRanges[range][0]
      );
    };

    onScroll();

    const onMouseMove = (event: MouseEvent) => {
      const maxClientX = innerWidth;
      const maxClientY = innerHeight;

      const clientX = event.clientX;
      const clientY = event.clientY;
      const normalizedX = (clientX - maxClientX / 2) / maxClientX;
      const normalizedY = (clientY - maxClientY / 2) / maxClientY;
      const normalizedZ = (clientY - maxClientY / 2) / maxClientY;

      /** Move light based on mouse movement gsap */
      gsap.to(directionalLight.current!.position, {
        duration: 1,
        x: initialLightPosition.current.x - normalizedX * 0,
        y: initialLightPosition.current.y - normalizedY * 240,
        z: initialLightPosition.current.z - normalizedX * 320,
      });
    };

    // addEventListener('load', onLoad);
    addEventListener('scroll', onScroll);
    addEventListener('mousemove', onMouseMove);

    return () => {
      // removeEventListener('load', onLoad);
      removeEventListener('scroll', onScroll);
      removeEventListener('mousemove', onMouseMove);
    };
  }, [isDark]);

  useEffect(() => {
    if (isDark) {
      ambientlight.current!.intensity = ambientLightIntensityRanges.dark[0];
    } else {
      ambientlight.current!.intensity = ambientLightIntensityRanges.light[0];
    }
  }, [isDark]);

  return (
    <React.Fragment>
      {/* <OrbitControls /> */}
      {/* <axesHelper /> */}

      <fog
        attach="fog"
        color={isDark ? 'black' : 'rgba(255, 255, 255, 0)'}
        far={isDark ? 30 : 15}
        near={isDark ? -20 : 0}
      />

      <Physics>
        <GallerySceneObject scale={[0.03, 0.03, 0.03]} isDark={isDark} />
      </Physics>

      <ambientLight
        ref={ambientlight}
        intensity={ambientLightIntensityRanges[isDark ? 'dark' : 'light'][0]}
      />
      <directionalLight
        ref={directionalLight}
        intensity={
          directionalLightIntensityRanges[isDark ? 'dark' : 'light'][1]
        }
        position={initialLightPosition.current}
        castShadow={true}
        shadow-mapSize-height={2048}
        shadow-mapSize-width={2048}
      />
      {/* <directionalLight
        intensity={3}
        position={[0, 10, 50]}
        castShadow={true}
        shadow-mapSize-height={256}
        shadow-mapSize-width={256}
      /> */}

      {/* <SoftShadows size={0.2} samples={20} /> */}

      <EffectComposer>
        <Noise opacity={isDark ? 0.05 : 0.1} />
        <Vignette
          eskil={true}
          offset={0.25}
          darkness={0.85}
          blendFunction={isDark ? BlendFunction.DARKEN : BlendFunction.LIGHTEN}
        />
      </EffectComposer>
    </React.Fragment>
  );
};

export const GalleryScene = () => {
  return (
    <Root>
      <Canvas camera={{ fov: 40 }} shadows={true}>
        <Suspense>
          <GallerySceneContents />
        </Suspense>
      </Canvas>
    </Root>
  );
};

const Root = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
`;
