/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei';
import { RapierRigidBody, RigidBody } from '@react-three/rapier';
import { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
  nodes: {
    Snake_plant_Cylinder001: THREE.Mesh;
    Painting: THREE.Mesh;
    Cylinder_Cylinder_Faux_Pine_Floorboards003: THREE.Mesh;
    Male_Male_Faux_Pine_Floorboards003: THREE.Mesh;
    Male_Male_Faux_Pine_Floorboards001: THREE.Mesh;
    Mesh_3005: THREE.Mesh;
    Mesh_3005_1: THREE.Mesh;
    Mesh_3006: THREE.Mesh;
    Mesh_3006_1: THREE.Mesh;
    Wall_1: THREE.Mesh;
    Wall_3: THREE.Mesh;
    Stairs: THREE.Mesh;
    Pillar: THREE.Mesh;
    Bottom: THREE.Mesh;
    Top: THREE.Mesh;
    Middle: THREE.Mesh;
    Array_Circle: THREE.Mesh;
    Floor: THREE.Mesh;
    BezierCurve001: THREE.Mesh;
    Cylinder001: THREE.Mesh;
    Sphere001: THREE.Mesh;
    Cylinder001_1: THREE.Mesh;
    Cylinder001_2: THREE.Mesh;
    Cylinder001_3: THREE.Mesh;
    Cylinder001_4: THREE.Mesh;
    Sphere001_1: THREE.Mesh;
    Sphere001_2: THREE.Mesh;
    Sphere001_3: THREE.Mesh;
    Sphere001_4: THREE.Mesh;
    BezierCurve001_1: THREE.Mesh;
    BezierCurve001_2: THREE.Mesh;
    BezierCurve001_3: THREE.Mesh;
    BezierCurve001_4: THREE.Mesh;
    Pole_String: THREE.Mesh;
    Carpet: THREE.Mesh;
  };
  materials: {
    ['Clear White Material']: THREE.MeshStandardMaterial;
  };
};

export const GallerySceneObject: React.FC<
  JSX.IntrinsicElements['group'] & {
    isDark: boolean;
  }
> = ({ isDark, ...props }) => {
  const { nodes, materials } = useGLTF(
    '/3d/GallerySceneObject.glb'
  ) as unknown as GLTFResult;

  // change materials to be more reflective
  materials['Clear White Material'].metalness = isDark ? 0.8 : 0.4;
  materials['Clear White Material'].roughness = isDark ? 0.75 : 0.5;

  const head1 = useRef<RapierRigidBody>(null);
  const handleHead1Interaction = () => {
    if (!head1.current) return;
    head1.current.applyImpulse({ x: -0.0005, y: 0.01, z: 0.0005 }, true);
  };
  const head2 = useRef<RapierRigidBody>(null);
  const handleHead2Interaction = () => {
    if (!head2.current) return;
    head2.current.applyImpulse({ x: -0.0005, y: 0.01, z: 0.0005 }, true);
  };
  const head3 = useRef<RapierRigidBody>(null);
  const handleHead3Interaction = () => {
    if (!head3.current) return;
    head3.current.applyImpulse({ x: -0.0005, y: 0.01, z: 0.0005 }, true);
  };

  const [interactiveRigidBodyType, setInteractiveRigidBodyType] = useState<
    'dynamic' | 'fixed'
  >('fixed');

  useEffect(() => {
    setTimeout(() => {
      setInteractiveRigidBodyType('dynamic');
    }, 1000);
  });

  return (
    <group {...props} dispose={null}>
      <RigidBody type="fixed" colliders="hull">
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Snake_plant_Cylinder001.geometry}
          material={materials['Clear White Material']}
          position={[0, 0, 19.348]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Painting.geometry}
          material={materials['Clear White Material']}
          position={[-32.626, 27.769, 268.43]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={1.178}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder_Cylinder_Faux_Pine_Floorboards003.geometry}
          material={materials['Clear White Material']}
        />
      </RigidBody>

      <RigidBody colliders="hull" type={interactiveRigidBodyType} ref={head1}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Male_Male_Faux_Pine_Floorboards003.geometry}
          material={materials['Clear White Material']}
          position={[-0.92, 1.047, -0.425]}
          rotation={[-0.025, 0, 0]}
          onPointerEnter={handleHead1Interaction}
        />
      </RigidBody>
      <RigidBody colliders="hull" type={interactiveRigidBodyType} ref={head2}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Male_Male_Faux_Pine_Floorboards001.geometry}
          material={materials['Clear White Material']}
          position={[-0.93, 0.94, -0.399]}
          rotation={[-0.067, 0, 0]}
          onPointerEnter={handleHead2Interaction}
        />
      </RigidBody>

      <RigidBody colliders="hull" type={interactiveRigidBodyType} ref={head3}>
        <group
          position={[-1.027, 0.175, -0.438]}
          rotation={[-0.068, -0.036, 0.142]}
          onPointerEnter={handleHead3Interaction}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_3006.geometry}
            material={materials['Clear White Material']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_3006_1.geometry}
            material={materials['Clear White Material']}
          />
        </group>
      </RigidBody>

      <RigidBody type="fixed" colliders="hull">
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Wall_1.geometry}
          material={materials['Clear White Material']}
          position={[-14.709, -0.442, 9.184]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Wall_3.geometry}
          material={materials['Clear White Material']}
          position={[-14.709, -0.442, 13.262]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Stairs.geometry}
          material={materials['Clear White Material']}
          position={[57.215, 0, 147.202]}
          rotation={[0, -1.571, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Pillar.geometry}
          material={materials['Clear White Material']}
          position={[-41.714, -52.891, 119.836]}
        />

        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bottom.geometry}
          material={materials['Clear White Material']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Top.geometry}
          material={materials['Clear White Material']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Middle.geometry}
          material={materials['Clear White Material']}
          position={[0, -35.547, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Array_Circle.geometry}
          material={nodes.Array_Circle.material}
          position={[0, -35.832, 0]}
          rotation={[0, Math.PI / 8, 0]}
        />
      </RigidBody>

      <RigidBody type="fixed" colliders="hull">
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Floor.geometry}
          material={materials['Clear White Material']}
        />
      </RigidBody>
      <RigidBody colliders="hull" type="fixed">
        <group
          position={[30.491, -23.202, 1.619]}
          rotation={[0, -Math.PI / 4, 0]}
        >
          {/* <mesh
          castShadow
          receiveShadow
          geometry={nodes.BezierCurve001.geometry}
          material={materials['Clear White Material']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder001.geometry}
          material={materials['Clear White Material']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sphere001.geometry}
          material={materials['Clear White Material']}
        /> */}
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder001_1.geometry}
            material={materials['Clear White Material']}
            position={[-58.457, -30.682, 21.083]}
            rotation={[Math.PI, -Math.PI / 4, Math.PI]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder001_2.geometry}
            material={materials['Clear White Material']}
            position={[-22.038, -30.682, 56.167]}
            rotation={[0, -Math.PI / 4, 0]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder001_3.geometry}
            material={materials['Clear White Material']}
            position={[13.046, -30.682, 19.748]}
            rotation={[0, Math.PI / 4, 0]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder001_4.geometry}
            material={materials['Clear White Material']}
            position={[-23.372, -30.682, -15.336]}
            rotation={[-Math.PI, Math.PI / 4, -Math.PI]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Sphere001_1.geometry}
            material={materials['Clear White Material']}
            position={[-58.457, -30.682, 21.083]}
            rotation={[Math.PI, -Math.PI / 4, Math.PI]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Sphere001_2.geometry}
            material={materials['Clear White Material']}
            position={[-22.038, -30.682, 56.167]}
            rotation={[0, -Math.PI / 4, 0]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Sphere001_3.geometry}
            material={materials['Clear White Material']}
            position={[13.046, -30.682, 19.748]}
            rotation={[0, Math.PI / 4, 0]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Sphere001_4.geometry}
            material={materials['Clear White Material']}
            position={[-23.372, -30.682, -15.336]}
            rotation={[-Math.PI, Math.PI / 4, -Math.PI]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.BezierCurve001_1.geometry}
            material={materials['Clear White Material']}
            position={[-58.457, -30.682, 21.083]}
            rotation={[Math.PI, -Math.PI / 4, Math.PI]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.BezierCurve001_2.geometry}
            material={materials['Clear White Material']}
            position={[-22.038, -30.682, 56.167]}
            rotation={[0, -Math.PI / 4, 0]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.BezierCurve001_3.geometry}
            material={materials['Clear White Material']}
            position={[13.046, -30.682, 19.748]}
            rotation={[0, Math.PI / 4, 0]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.BezierCurve001_4.geometry}
            material={materials['Clear White Material']}
            position={[-23.372, -30.682, -15.336]}
            rotation={[-Math.PI, Math.PI / 4, -Math.PI]}
          />
        </group>
      </RigidBody>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pole_String.geometry}
        material={materials['Clear White Material']}
        position={[0, -20.233, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Carpet.geometry}
        material={materials['Clear White Material']}
        position={[56.34, -53.465, 0]}
        scale={26.88}
      />

      <RigidBody type="fixed" colliders="hull">
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_3005.geometry}
          material={materials['Clear White Material']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_3005_1.geometry}
          material={materials['Clear White Material']}
        />
      </RigidBody>
    </group>
  );
};

useGLTF.preload('/3d/GallerySceneObject.glb');
